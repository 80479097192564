import { useState } from 'react'
import saveHours from '../utils/saveToDb/saveHours'
import { SectionLoader } from '../components/Loader'
import useSWR from 'swr'
import { PageLoader } from '../components/Loader'
import { isMobile } from 'react-device-detect'

const fetcher = (url) => fetch(url).then((res) => res.json())
const hoursUrl = 'https://qualityearthwork.com/php/getHours.php'


const EditHours = () => {

    const [message, setMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [hours, setHours] = useState({
        Monday: undefined,
        Tuesday: undefined,
        Wednesday: undefined,
        Thursday: undefined,
        Friday: undefined,
        Saturday: undefined,
        Sunday: undefined,
    })

    const { data, error, isLoading: hoursIsLoading } = useSWR(hoursUrl, fetcher)

    if (!data || error) return (
        <div>
            <h1>Error.</h1>
            <h2>Server error. Refresh the page.</h2>
        </div>
    )

    if (hoursIsLoading) return <PageLoader />

    const handleChange = (e) => {
        setMessage('')
        setHours(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const res = await saveHours({
            Monday: hours['Monday']
                ? hours['Monday']
                : data[0].Monday,
            Tuesday: hours['Tuesday']
                ? hours['Tuesday']
                : data[0].Tuesday,
            Wednesday: hours['Wednesday']
                ? hours['Wednesday']
                : data[0].Wednesday,
            Thursday: hours['Thursday']
                ? hours['Thursday']
                : data[0].Thursday,
            Friday: hours['Friday']
                ? hours['Friday']
                : data[0].Friday,
            Saturday: hours['Saturday']
                ? hours['Saturday']
                : data[0].Saturday,
            Sunday: hours['Sunday']
                ? hours['Sunday']
                : data[0].Sunday,
        })
        setIsLoading(false)
        res && setMessage('Success!')
    }

    if (!data) return <h2>Error.</h2>

    return (
        <div style={styles.main}>
            <h2 style={styles.heading}>Edit business hours.</h2>

            <div style={styles.dayContainer}>
                <div style={styles.group}>
                    <div style={styles.label}>Monday</div>
                    <input
                        type="text"
                        name="Monday"
                        value={
                            hours['Monday']
                                ? hours['Monday']
                                : data[0].Monday
                        }
                        className="inputField"
                        onChange={handleChange}
                        style={styles.input}
                    />
                </div>

                <div style={styles.group}>
                    <div style={styles.label}>Tuesday</div>
                    <input
                        type="text"
                        name="Tuesday"
                        value={
                            hours['Tuesday']
                                ? hours['Tuesday']
                                : data[0].Tuesday
                        }
                        className="inputField"
                        onChange={handleChange}
                        style={styles.input}
                    />
                </div>

                <div style={styles.group}>
                    <div style={styles.label}>Wednesday</div>
                    <input
                        type="text"
                        name="Wednesday"
                        value={
                            hours['Wednesday']
                                ? hours['Wednesday']
                                : data[0].Wednesday
                        }
                        className="inputField"
                        onChange={handleChange}
                        style={styles.input}
                    />
                </div>

                <div style={styles.group}>
                    <div style={styles.label}>Thursday</div>
                    <input
                        type="text"
                        name="Thursday"
                        value={
                            hours['Thursday']
                                ? hours['Thursday']
                                : data[0].Thursday
                        }
                        className="inputField"
                        onChange={handleChange}
                        style={styles.input}
                    />
                </div>

                <div style={styles.group}>
                    <div style={styles.label}>Friday</div>
                    <input
                        type="text"
                        name="Friday"
                        value={
                            hours['Friday']
                                ? hours['Friday']
                                : data[0].Friday
                        }
                        className="inputField"
                        onChange={handleChange}
                        style={styles.input}
                    />
                </div>

                <div style={styles.group}>
                    <div style={styles.label}>Saturday</div>
                    <input
                        type="text"
                        name="Saturday"
                        value={
                            hours['Saturday']
                                ? hours['Saturday']
                                : data[0].Saturday
                        }
                        className="inputField"
                        onChange={handleChange}
                        style={styles.input}
                    />
                </div>

                <div style={styles.group}>
                    <div style={styles.label}>Sunday</div>
                    <input
                        type="text"
                        name="Sunday"
                        value={
                            hours['Sunday']
                                ? hours['Sunday']
                                : data[0].Sunday
                        }
                        className="inputField"
                        onChange={handleChange}
                        style={styles.input}
                    />
                </div>
            </div>

            <div style={styles.submitDiv}>
                <input
                    type="submit"
                    value="SUBMIT"
                    className="submit"
                    style={styles.submit}
                    onClick={handleSubmit}
                />
            </div>

            <span style={styles.success}>{message}</span>

            {isLoading && <SectionLoader />}
        </div>
    )
}

const styles = {
    main: {
        margin: 'auto',
    },
    dayContainer: {
        padding: 30,
    },
    group: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    heading: {
        textAlign: isMobile ? 'center' : 'left',
        marginTop: isMobile ? 40 : 'auto',
    },
    label: {
        fontSize: 20,
        flex: 2,
    },
    success: {
        color: '#04aa6d',
        fontSize: 20,
        marginLeft: 20,
    },
    input: {
        flex: 4,
    },
    submit: {},
    submitDiv: {
        display: 'flex',
        justifyContent: 'center'
    },
}

export default EditHours