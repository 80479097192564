import { removeApos } from '../regexInput'

const saveReviews = async (rawData) => {
    const data = {
        id: rawData.id ? rawData.id : null,
        review: removeApos(rawData.review),
        name: removeApos(rawData.name),
        stars: rawData.stars,
    }

    const url = 'https://qualityearthwork.com/php/postReviews.php'

    await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .catch((e) => console.log('Error saving Reviews: ', e.message))

    return true
}

export default saveReviews