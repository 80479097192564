import { isMobile } from 'react-device-detect'

const HoursComp = (props) => {
    const { hoursData } = props
    const data = hoursData[0]

    return (
        <div style={styles.main}>
            <div style={styles.day}>
                <div>Monday</div>
                <div>{data.Monday}</div>
            </div>

            <div style={styles.day}>
                <div>Tuesday</div>
                <div>{data.Tuesday}</div>
            </div>

            <div style={styles.day}>
                <div>Wednesday</div>
                <div>{data.Wednesday}</div>
            </div>

            <div style={styles.day}>
                <div>Thursday</div>
                <div>{data.Thursday}</div>
            </div>

            <div style={styles.day}>
                <div>Friday</div>
                <div>{data.Friday}</div>
            </div>

            <div style={styles.day}>
                <div>Saturday</div>
                <div>{data.Saturday}</div>
            </div>

            <div style={styles.day}>
                <div>Sunday</div>
                <div>{data.Sunday}</div>
            </div>
        </div>
    )
}

const styles = {
    main: {
        textAlign: isMobile ? 'center' : 'right',
        flex: 1,
        width: isMobile ? '75%' : 'auto',
        maxWidth: 275,
    },
    day: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: '0.9rem',
    },
}

export default HoursComp