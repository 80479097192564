import { useState } from 'react'
import useSWR from 'swr'
import { isMobile } from 'react-device-detect'
import { PageLoader, SectionLoader } from '../components/Loader'
import ListOfReviews from './ListOfReviews'
import '../styles/forms.css'
import saveReviews from '../utils/saveToDb/saveReviews'
import { addApos, removeApos } from '../utils/regexInput'

const fetcher = (url) => fetch(url).then((res) => res.json())
const url = 'https://qualityearthwork.com/php/getReviews.php'

const EditReviews = () => {

  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [review, setReview] = useState('')
  const [name, setName] = useState('')
  const [stars, setStars] = useState('')

  const { data, error, isLoading: servicesIsLoading } = useSWR(url, fetcher, { refreshInterval: 2000 })

  if (servicesIsLoading) return <PageLoader />
  if (error || !data) return (
    <div>
      <h1>Error.</h1>
      <h2>Server error. Refresh the page.</h2>
    </div>
  )

  const handleChangeReview = (e) => {
    setMessage('')
    setReview(e.target.value)
  }

  const handleChangeName = (e) => {
    setMessage('')
    setName(e.target.value)
  }

  const handleChangeStars = (e) => {
    setMessage('')
    setStars(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const res = await saveReviews({
      review: removeApos(review),
      name: removeApos(name),
      stars
    })
    setIsLoading(false)
    res && setMessage('Success!')
    setReview('')
    setName('')
    setStars('')
  }

  return (
    <div style={styles.main}>
      <h2 style={styles.heading}>Add a Review.</h2>

      <div style={styles.form}>
        <div style={styles.group}>
          <div style={styles.label}>Review</div>
          <textarea
            type="text"
            name="review"
            value={addApos(review)}
            className="inputField"
            onChange={handleChangeReview}
            style={styles.textarea}
          />
        </div>

        <div style={styles.group}>
          <div style={styles.label}>Name</div>
          <input
            type="text"
            name="name"
            value={addApos(name)}
            className="inputField"
            onChange={handleChangeName}
            style={styles.input}
          />
        </div>

        <div style={styles.group}>
          <div style={styles.label}>Stars</div>
          <input
            type='text'
            name="stars"
            value={addApos(stars)}
            className="inputField"
            onChange={handleChangeStars}
            style={styles.input}
            placeholder='1 to 5 stars (integer only)'
          />
        </div>

        <div style={styles.submit}>
          <input
            type="submit"
            value="SUBMIT"
            className="submit"
            onClick={handleSubmit}
          />
        </div>

        <p style={styles.success}>{message}</p>

        {isLoading && <SectionLoader />}
      </div>

      <ListOfReviews data={data} />
    </div>
  )
}

const styles = {
  main: {
    margin: 'auto',
  },
  form: {
    padding: isMobile ? 20 : 30,
    border: '1px solid',
    margin: isMobile ? 5 : 30,
  },
  group: {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  heading: {
    textAlign: isMobile ? 'center' : 'left',
    marginTop: isMobile ? 40 : 'auto',
    marginBottom: isMobile ? 20 : 'auto',
  },
  label: {
    fontSize: 20,
    flex: 2,
  },
  success: {
    color: '#04aa6d',
    fontSize: 20,
    marginLeft: 20,
    textAlign: 'right'
  },
  input: {
    flex: 4,
  },
  textarea: {
    flex: 4,
    height: 100,
  },
  submit: {
    width: '100%',
    margin: 'auto',
    textAlign: isMobile ? 'center' : 'right',
    marginTop: 20,
  }
}

export default EditReviews
