import { useState } from 'react'
import useSWR from 'swr'
import { isMobile } from 'react-device-detect'
import { useParams, useNavigate } from 'react-router-dom'
import { PageLoader } from '../components/Loader'
import { SectionLoader } from '../components/Loader'
import { addApos } from '../utils/regexInput'
import saveReviews from '../utils/saveToDb/saveReviews'

const secretPassword = process.env.REACT_APP_PASSWORD
const fetcher = (url) => fetch(url).then((res) => res.json())

const EditReview = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const savedPassword = sessionStorage.getItem('password')

    const [isLoading, setIsLoading] = useState(false)
    const [name, setName] = useState('')
    const [review, setReview] = useState('')
    const [stars, setStars] = useState('')
    const [message, setMessage] = useState('')

    const servicesUrl = `https://qualityearthwork.com/php/getReview.php?id=${id}`

    const { data, error, isLoading: reviewIsLoading } = useSWR(servicesUrl, fetcher, { refreshInterval: 1000 })

    if (reviewIsLoading) return <PageLoader />
    if (error || !data) return (
        <div>
            <h1>Error.</h1>
            <h2>Server error. Refresh the page.</h2>
        </div>
    )

    const goBack = () => navigate(-1)

    const handleChangeName = (e) => {
        setMessage('')
        setName(e.target.value)
    }

    const handleChangeReview = (e) => {
        setReview(e.target.value)
        setMessage('')
    }

    const handleChangeStars = (e) => {
        setMessage('')
        setStars(e.target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const res = await saveReviews({
            id: data.id,
            name: name ? name : data.name,
            review: review ? review : data.review,
            stars: stars ? stars : data.stars,
        })
        setIsLoading(false)
        res && setMessage('Success!')
        setIsLoading(false)
    }

    if (secretPassword !== savedPassword) return <div><h1>You are not authorized to view this page.</h1></div>

    return (
        <div style={styles.main}>

            <div style={styles.header}>
                <h2 style={styles.serviceTitle}>
                    Edit.
                </h2>

                <button
                    onClick={goBack}
                    style={styles.back}
                >Back to Admin</button>
            </div>

            <div style={styles.form}>

                <div style={styles.group}>
                    <div style={styles.label}>Review</div>
                    <textarea
                        type="text"
                        name="review"
                        value={
                            review
                                ? addApos(review)
                                : addApos(data.review)
                        }
                        className="inputField"
                        onChange={handleChangeReview}
                        style={styles.textarea}
                    />
                </div>

                <div style={styles.group}>
                    <div style={styles.label}>Name</div>
                    <input
                        type="text"
                        name="name"
                        value={
                            name
                                ? addApos(name)
                                : addApos(data.name)
                        }
                        className="inputField"
                        onChange={handleChangeName}
                        style={styles.input}
                    />
                </div>

                <div style={styles.group}>
                    <div style={styles.label}>Stars</div>
                    <input
                        type="text"
                        name="stars"
                        value={
                            stars
                                ? stars
                                : data.stars
                        }
                        className="inputField"
                        onChange={handleChangeStars}
                        style={styles.input}
                    />
                </div>

                <div style={styles.submit}>
                    <input
                        type="submit"
                        value="SUBMIT"
                        className="submit"
                        onClick={handleSubmit}
                    />
                </div>

                <p style={styles.success}>{message}</p>

                {isLoading && <SectionLoader />}

            </div>
        </div>
    )
}

const styles = {
    main: {
        margin: 'auto',
    },
    header: {
        display: 'flex',
        flexDirection: isMobile ? 'column-reverse' : 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    back: {
        transform: 'scale(75%)',
    },
    serviceTitle: {
        textAlign: isMobile ? 'center' : 'left',
        marginTop: isMobile ? 30 : 'auto',
    },
    form: {
        padding: isMobile ? 20 : 30,
        border: '1px solid',
        margin: isMobile ? 5 : 30,
    },
    group: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    label: {
        fontSize: 20,
        flex: 2,
    },
    success: {
        color: '#04aa6d',
        fontSize: 20,
        marginLeft: 20,
        textAlign: 'right'
    },
    input: {
        flex: 4,
    },
    textarea: {
        flex: 4,
        height: 100,
    },
    submit: {
        width: '100%',
        margin: 'auto',
        textAlign: isMobile ? 'center' : 'right',
        marginTop: 20,
    },
    imageDiv: {
        marginBottom: 40,
    },
}

export default EditReview