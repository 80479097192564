import { removeApos } from '../regexInput'

const saveHome = async (rawData) => {
    const data = {
        callToAction: removeApos(rawData.callToAction),
        skillsTitle: removeApos(rawData.skillsTitle),
        skills: removeApos(rawData.skills),
        innovationTitle: removeApos(rawData.innovationTitle),
        innovation: removeApos(rawData.innovation),
        customerServiceTitle: removeApos(rawData.customerServiceTitle),
        customerService: removeApos(rawData.customerService),
        homeImages: rawData.homeImages
    }

    const url = 'https://qualityearthwork.com/php/postHome.php'

    await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .catch((e) => console.log('Error saving Home: ', e.message))

    return true
}

export default saveHome