import { useEffect, useState } from 'react'
import useSWR from 'swr'
import { isMobile } from 'react-device-detect'
import banner from '../assets/banner.png'
import bannerInverted from '../assets/bannerInverted.png'
import TeamComp from '../components/TeamComp'
import { PageLoader } from '../components/Loader'
import MetaDecorator from '../components/MetaDecorator'
import { addApos } from '../utils/regexInput'

const fetcher = (url) => fetch(url).then((res) => res.json())
const homeUrl = 'https://qualityearthwork.com/php/getHome.php'
const teamUrl = 'https://qualityearthwork.com/php/getTeam.php'

const About = () => {

    const [mode, setMode] = useState('')


    useEffect(() => {
        if (
            window.matchMedia &&
            window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
            setMode('dark')
        } else {
            setMode('light')
        }

        window
            .matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', (event) => {
                const newColorScheme = event.matches ? 'dark' : 'light'
                setMode(newColorScheme)
            })
    }, [])

    const source = mode === 'light' ? banner : bannerInverted

    const { data: teamData, error: teamError, isLoading: teamIsLoading } = useSWR(teamUrl, fetcher)
    const { data: homeData, error: homeError, isLoading: homeIsLoading } = useSWR(homeUrl, fetcher)

    const skills = homeData && addApos(homeData[0].skills)
    const skillsTitle = homeData && addApos(homeData[0].skillsTitle)
    const innovation = homeData && addApos(homeData[0].innovation)
    const innovationTitle = homeData && addApos(homeData[0].innovationTitle)
    const customerService = homeData && addApos(homeData[0].customerService)
    const customerServiceTitle = homeData && addApos(homeData[0].customerServiceTitle)

    if (teamError || homeError) return (
        <div>
            <h1>Error.</h1>
            <h3 style={{ marginLeft: 8 }}>Please refresh the page.</h3>
        </div>
    )
    if (teamIsLoading || homeIsLoading) return <PageLoader />

    return (
        <div style={styles.main}>
            <MetaDecorator />

            <div style={styles.imageDiv}>
                <img
                    src={source}
                    style={{
                        width: isMobile ? '100%' : '60%'
                    }}
                    alt="Quality Earthwork & Hauling"
                />
            </div>

            <div style={styles.buttonContainer}>
                <a href='#company'
                    style={{
                        color: '#a80010',
                        fontWeight: 600,
                        display: 'inline',
                        textDecorationLine: 'underline',
                        fontSize: 20,
                        marginLeft: 5,
                    }}
                >
                    <button style={styles.button}>Our Company</button>
                </a>
                <a href='#team'
                    style={{
                        color: '#a80010',
                        fontWeight: 600,
                        display: 'inline',
                        textDecorationLine: 'underline',
                        fontSize: 20,
                        marginLeft: 5,
                    }}
                >
                    <button style={styles.button}>Our Team</button>
                </a>
            </div>

            <h1 id='company' style={styles.heading}>{skillsTitle}</h1>

            <p dangerouslySetInnerHTML={{ __html: skills }}></p>

            <h1 style={styles.heading}>{innovationTitle}</h1>

            <p dangerouslySetInnerHTML={{ __html: innovation }}></p>

            <h1 style={styles.heading}>{customerServiceTitle}</h1>

            <p dangerouslySetInnerHTML={{ __html: customerService }}></p>

            <TeamComp teamData={teamData} />

        </div>
    )
}

const styles = {
    main: {
        width: isMobile ? '90%' : '100%',
        margin: 'auto',
    },
    imageDiv: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 30,
    },
    heading: {
        fontSize: isMobile ? 50 : 60,
        marginTop: 80,
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        width: isMobile ? 150 : 200,
        fontSize: isMobile ? 17 : '',
        marginBottom: isMobile && 8,
        padding: isMobile ? '16px 16px' : '16px 32px'
    }
}

export default About