import { removeApos } from '../regexInput'

const saveTeam = async (rawData) => {
    const data = {
        id: rawData.id,
        name: removeApos(rawData.name),
        email: rawData.email,
        description: removeApos(rawData.description),
        place: rawData.place,
        image: rawData.image,
    }

    const url = 'https://qualityearthwork.com/php/postTeam.php'

    await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .catch((e) => console.log('Error saving Team: ', e.message))

    return true
}

export default saveTeam