import { useState } from 'react'
import useSWR from 'swr'
import { PageLoader, SectionLoader } from '../components/Loader'
import saveAddress from '../utils/saveToDb/saveAddress'
import { addApos } from '../utils/regexInput'
import { isMobile } from 'react-device-detect'
import '../styles/forms.css'

const fetcher = (url) => fetch(url).then((res) => res.json())
const addressUrl = 'https://qualityearthwork.com/php/getAddress.php'

const EditAddress = () => {
    const [message, setMessage] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [address, setAddress] = useState({
        companyName: undefined,
        location: undefined,
        phone: undefined,
    })

    const { data, error, isLoading: addressIsLoading } = useSWR(addressUrl, fetcher, { refreshInterval: 1000 })

    if (addressIsLoading) return <PageLoader />
    if (error || !data) return (
        <div>
            <h1>Error.</h1>
            <h2>Server error. Refresh the page.</h2>
        </div>
    )

    const handleChange = (e) => {
        setMessage('')
        setAddress(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        const res = await saveAddress({
            companyName: address['companyName']
                ? address['companyName']
                : data[0].companyName,
            location: address['location']
                ? address['location']
                : data[0].location,
            phone: address['phone']
                ? address['phone']
                : data[0].phone,
        })
        setIsLoading(false)
        res && setMessage('Success!')
    }

    return (
        <div style={styles.main}>
            <h2 style={styles.heading}>Edit business Name, Location, and Phone Number.</h2>

            <div style={styles.dayContainer}>
                <div style={styles.group}>
                    <div style={styles.label}>Company Name</div>
                    <input
                        type="text"
                        name="companyName"
                        value={
                            address['companyName']
                                ? addApos(address['companyName'])
                                : addApos(data[0].companyName)
                        }
                        className="inputField"
                        onChange={handleChange}
                        style={styles.input}
                    />
                </div>

                <div style={styles.group}>
                    <div style={styles.label}>Location</div>
                    <input
                        type="text"
                        name="location"
                        value={
                            address['location']
                                ? addApos(address["location"])
                                : addApos(data[0].location)
                        }
                        className="inputField"
                        onChange={handleChange}
                        style={styles.input}
                    />
                </div>

                <div style={styles.group}>
                    <div style={styles.label}>Phone number</div>
                    <input
                        type="text"
                        name="phone"
                        value={
                            address['phone']
                                ? address['phone']
                                : data[0].phone
                        }
                        className="inputField"
                        onChange={handleChange}
                        style={styles.input}
                    />
                </div>
            </div>

            <div style={styles.submitDiv}>
                <input
                    type="submit"
                    value="SUBMIT"
                    className="submit"
                    style={styles.submit}
                    onClick={handleSubmit}
                />
            </div>

            <span style={styles.success}>{message}</span>

            {isLoading && <SectionLoader />}
        </div>
    )
}

const styles = {
    main: {
        margin: 'auto',
    },
    dayContainer: {
        padding: 30,
    },
    group: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    heading: {
        textAlign: isMobile ? 'center' : 'left',
        marginTop: isMobile ? 40 : 'auto',
    },
    label: {
        fontSize: 20,
        flex: 2,
    },
    success: {
        color: '#04aa6d',
        fontSize: 20,
        marginLeft: 20,
    },
    input: {
        flex: 4
    },
    submit: {},
    submitDiv: {
        display: 'flex',
        justifyContent: 'center'
    },
}

export default EditAddress