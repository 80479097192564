import deleteReview from '../utils/saveToDb/deleteReview'
import { addApos } from '../utils/regexInput'
import { useNavigate } from 'react-router-dom'
import { isMobile } from 'react-device-detect'

const ListOfReviews = (props) => {
    let navigate = useNavigate()

    const { data } = props

    const deleteIt = async (e) => {
        if (window.confirm('Are you sure you want to delete this review?') === true) {
            await deleteReview(e.target.name)
        }
    }

    const editIt = (e) => navigate(`/review/${e.target.name}`)

    const renderItem = (item) => {
        return <div key={item.id} style={styles.itemContainer}>
            <div style={styles.headingAndButton}>

                <div style={styles.nameStars}>
                    <p style={styles.service}>Name: {addApos(item.name)}</p>

                    <p>Stars: {item.stars}</p>
                </div>

                <div style={styles.buttonContainer}>
                    <button
                        name={item.id}
                        style={styles.button}
                        onClick={deleteIt}
                    >Delete</button>

                    <button
                        name={item.id}
                        style={styles.button}
                        onClick={editIt}
                    >Edit</button>
                </div>
            </div>

            <p style={styles.description}
                dangerouslySetInnerHTML={{
                    __html: addApos(item.review),
                }}></p>

        </div>
    }

    return (
        <div style={styles.main}>
            <h2 style={styles.heading}>View, Edit, or Delete a Review.</h2>

            <div style={styles.list}>
                {data.map(item => renderItem(item))}
            </div>

        </div>
    )
}

const styles = {
    main: {
        marginTop: 60,
    },
    itemContainer: {
        border: '1px solid',
        margin: isMobile ? 0 : 30,
        padding: 30,
    },
    list: {
        marginTop: 20,
    },
    description: {
        lineHeight: 1.7
    },
    headingAndButton: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: isMobile ? 'column-reverse' : 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    nameStars: {
        flex: 1,
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: isMobile ? 20 : 0,
        width: isMobile ? '100%' : 'auto',
        flex: 1,
    },
    service: {
        flex: 5
    },
    button: {
        flex: 1,
        margin: 5,
        transform: 'scale(90%)'
    },
    heading: {
        textAlign: isMobile ? 'center' : 'left',
        marginTop: isMobile ? 40 : 'auto',
    },
}

export default ListOfReviews