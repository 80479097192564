import { removeApos } from '../regexInput'

const saveAddress = async (rawData) => {
    const data = {
        companyName: removeApos(rawData.companyName),
        location: removeApos(rawData.location),
        phone: rawData.phone
    }
    const url = 'https://qualityearthwork.com/php/postAddress.php'

    await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .catch((e) => console.log('Error saving Address: ', e.message))

    return true
}

export default saveAddress