import useSWR from 'swr'
import { PageLoader } from '../components/Loader'
import ServiceItem from '../components/ServiceItem'
import MetaDecorator from '../components/MetaDecorator'
import '../styles/Services.css'

const fetcher = (url) => fetch(url).then((res) => res.json())
const url = 'https://qualityearthwork.com/php/getServices.php'

// let count = 0
const Services = (props) => {
    //   console.log(count++);

    const { data, error, isLoading } = useSWR(url, fetcher)

    if (error || !data) return <div><h1>Error.</h1><h3>Server error. Please refresh the page.</h3></div>
    if (isLoading) return <PageLoader />

    return (
        <div className='about'>

            <MetaDecorator
                title="Quality Earthwork & Hauling LLC"
                description=" We are proud to contribute to the local economy and connect commercial and residential clients with the best earthwork services in the area."
                url="https://qualityearthwork.com/services"
            />

            <h1 style={styles.heading}>Our Services.</h1>

            <h3>Tap on a service.</h3>

            {data.map(item => <ServiceItem item={item} key={item.id} />)}

        </div>
    )
}

const styles = {
    heading: {
        marginBottom: 50,
    }
}

export default Services
