import { Helmet } from 'react-helmet'

const MetaDecorator = (props) => {
    const title = props.title
        ? props.title
        : 'Quality Earthwork & Hauling LLC'

    const description = props.description
        ? props.description
        : 'When you’re in need of an expert excavating contractor in Gerald, MO, or the surrounding areas, reach out to Quality Earthwork & Hauling. Give us a call today to learn more about all of the services that we provide or to request a free estimate.'

    const noscript = props.noscript
        ? props.noscript
        : 'Welcome to Quality Earthwork & Hauling! You need to enable JavaScript to visit this site.'

    const keywords = props.keywords
        ? props.keywords
        : "Quality Earthwork & Hauling, Earthwork, Hauling, stump removal, debris removal, land clearing, maintenance, repairs, asphalt, construction, excavation, ice treatment, snow removal, snow plowing, retaining walls, qualityearthwork, qualityearthwork.com, Gerald, Gerald MO, Gerald Missouri, Missouri, Union, Washington, Saint Louis, St Louis "

    const image = props.image ? props.image : "https://qualityearthwork.com/banner.png"

    const imageAlt = props.imageAlt ? props.imageAlt : 'Quality Earthwork'

    const url = props.url ? props.url : window.location.href

    const siteName = props.siteName ? props.siteName : 'Quality Earthwork'

    const jsonData = `[
        {
            "@context": "http://schema.org",
            "@type": "SoftwareApplication",
            "name": "Quality Earthwork & Hauling",
            "image": "https://qualityearthwork.com/banner.png",
            "url": "https://qualityearthwork.com/",
            "downloadUrl": "https://qualityearthwork.com/",
            "author": {
                "@type": "Person",
                "name": "qualityearthwork.com"
            }
        },
        {
            "@context": "http://schema.org",
            "@type": "SoftwareApplication",
            "name": "Services",
            "image": "https://qualityearthwork.com/banner.png",
            "url": "https://qualityearthwork.com/services",
            "downloadUrl": "https://qualityearthwork.com/",
            "author": {
                "@type": "Person",
                "name": "qualityearthwork.com"
            }
        },
        {
            "@context": "http://schema.org",
            "@type": "SoftwareApplication",
            "name": "Contact Quality Earthwork",
            "image": "https://qualityearthwork.com/banner.png",
            "url": "https://qualityearthwork.com/contact",
            "downloadUrl": "https://qualityearthwork.com/",
            "author": {
                "@type": "Person",
                "name": "qualityearthwork.com"
            }
        }
    ]`

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />

            <link
                rel="icon"
                type="image/x-icon"
                href="https://qualityearthwork.com/favicon.ico"
            />
            <link rel="apple-touch-icon" href="https://qualityearthwork.com/logo192.png" />
            <link rel="shortcut icon" href="\favicon.png" />


            <noscript>{noscript}</noscript>
            <meta name="keywords" content={keywords} />
            <meta name="author" content="https://akaDesignTech.com" />

            <meta property="og:title" content={title} />
            <meta property="og:type" content="website" />
            <meta property="og:image" content={image} />
            <meta property="og:url" content={url} />
            <meta property="og:description" content={description} />
            <meta property="og:site_name" content={siteName} />

            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Quality Earthwork" />
            <meta name="twitter:image:alt" content={imageAlt} />
            <meta name="twitter:description" content={description} />
            {/* <meta name="twitter:site" content="@powershotzz" />  */}

            {/* https://www.google.com/webmasters/markup-helper/u/0/?verticalId=4703&sourceId=107061666 */}
            <script type="application/ld+json">{`${jsonData}`}</script>
        </Helmet>
    )
}

export default MetaDecorator
