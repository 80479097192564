import { useState } from 'react'
import { addApos } from '../utils/regexInput'
import { isMobile } from 'react-device-detect'

const ServiceItem = (props) => {
    const { item } = props

    const [open, setOpen] = useState(false)

    const toggleOpen = () => setOpen(!open)

    if (!item) return null

    return (
        <div style={styles.container}>
            <h4
                style={styles.heading}
                onClick={toggleOpen}
            >{addApos(item.service)}</h4>

            {open &&
                <p style={styles.description}
                    dangerouslySetInnerHTML={{
                        __html: addApos(item.description),
                    }}
                ></p>
            }

        </div>
    )
}

const styles = {
    container: {
        cursor: 'pointer',
        margin: '10px auto',

    },
    heading: {
        fontSize: isMobile ? 25 : 35,
        backgroundColor: '#a80010',
        padding: '5px 0px 10px 20px',
        color: 'whitesmoke',
        borderRadius: 5,
    },
    description: {
        padding: 20,
        lineHeight: 1.75,
    },
}

export default ServiceItem