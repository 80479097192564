import useSWR from 'swr'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { isMobile } from 'react-device-detect'
import { SectionLoader } from './Loader'
import { addApos } from '../utils/regexInput'

const fetcher = (url) => fetch(url).then((res) => res.json())
const url = 'https://qualityearthwork.com/php/getReviews.php'

const ReviewsComp = () => {

    const { data, error, isLoading } = useSWR(url, fetcher)

    // const data = [{ "id": "1", "stars": "5", "review": " Lorem ipsum, dolor sit amet consectetur adipisicing elit. Culpa unde est nemo, laborum officiis ratione temporibus repellat blanditiis sequi quae porro rerum officia ducimus fugit accusantium amet ab fuga aspernatur?", "name": "anon" }, { "id": "1", "stars": "3", "review": "really great", "name": "anon" }]

    const settings = {
        dots: false,
        fade: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        waitForAnimate: false,
        autoplay: true,
        autoplaySpeed: 5500,
        cssEase: 'linear',
        pauseOnHover: true,
        arrows: false,
    }

    if (error) return null
    if (isLoading) return <SectionLoader />

    return (
        <div id='reviews' style={styles.container}>
            <Slider {...settings}>
                {data &&
                    data.map((rev) => {
                        let stars = ''
                        switch (rev.stars) {
                            case '4':
                                stars = '⭐⭐⭐⭐'
                                break
                            case '3':
                                stars = '⭐⭐⭐'
                                break
                            case '2':
                                stars = '⭐⭐'
                                break
                            case '1':
                                stars = '⭐'
                                break
                            default:
                                stars = '⭐⭐⭐⭐⭐'
                        }

                        return <div key={rev.id}>
                            <p style={styles.p}>
                                {addApos(rev.review)}{' '}
                                ~<span style={styles.name}>
                                    {addApos(rev.name)}
                                </span>
                            </p>
                            <p style={styles.p}>{stars}</p>
                        </div>
                    })}
            </Slider>
        </div>
    )
}

const styles = {
    container: {
        maxWidth: isMobile ? '80%' : '75%',
        margin: 'auto',
    },
    p: {
        margin: 0,
    },
    name: {
        fontStyle: 'italic',
    },
}

export default ReviewsComp