import { isPhoto, isVideo } from '../utils/findMediaType'
import { isMobile } from 'react-device-detect'
import ImageDeleteButton from './ImageDeleteButton'

const oops = 'https://betanews.com/wp-content/uploads/2015/07/oops.jpg'
const maxW = isMobile ? 100 : 200
const maxH = isMobile ? 100 : 200

const ImageDisplayer = (props) => {
  const { imageArray, setAllData } = props
  let array = props.imageArray && props.imageArray.length > 0 ? props.imageArray.split(',') : []

  const images = []

  array.map((i) => {
    const item = i.trim()
    if (
      !item ||
      item === '' ||
      item === 'undefined' ||
      item === null ||
      item === 'null'
    ) {
      return null
    } else if (isPhoto(item)) {
      return images.push({ photo: item })
    } else if (isVideo(item)) {
      return images.push({ video: item })
    } else {
      return images.push({ url: item })
    }
  })

  const displayImage = (i) => {
    const item = new Image()
    item.src = i
    const w = item.width
    const h = item.height

    if (i === oops) {
      const nHeight = (maxW * h) / w
      return (
        <div style={styles.imageDiv} key={i}>
          <img
            src={i}
            alt={i}
            width={maxW}
            height={nHeight}
            style={styles.center}
          />
          <p style={styles.errorText}>Not able to display this image...</p>
        </div>
      )
    }

    if (w > h) {
      const nHeight = (maxW * h) / w
      return (
        <div style={styles.imageDiv} key={i}>
          <ImageDeleteButton
            file={i}
            imageArray={imageArray}
            setAllData={setAllData}
          />
          <img
            src={i}
            alt={i}
            width={maxW}
            height={nHeight}
            style={styles.center}
          />
          <p style={styles.smallText}>{i}</p>
        </div>
      )
    } else {
      const nWidth = (w * maxH) / h
      return (
        <div style={styles.imageDiv} key={i}>
          <ImageDeleteButton
            file={i}
            imageArray={imageArray}
            setAllData={setAllData}
          />
          <img
            src={i}
            alt={i}
            width={nWidth ? nWidth : maxW}
            height={maxH}
            style={styles.center}
          />
          <p style={styles.smallText}>{i}</p>
        </div>
      )
    }
  }

  return images ? (
    <div style={styles.main}>
      {images.map((img) => {
        if (img.url === 'undefined') return null
        if (img.photo) {
          return displayImage(img.photo)
        } else if (img.video) {
          const ext = img.video.split('.').pop()
          return (
            <div style={styles.imageDiv} key={img.video}>
              <ImageDeleteButton
                file={img.video}
                imageArray={imageArray}
                setAllData={setAllData}
              />
              <video width={maxW} controls>
                <source src={img.video} type={`video/${ext}`} />
                Your browser does not support this video.
              </video>
              <p style={styles.smallText}>{img.video}</p>
            </div>
          )
        } else if (img.url) {
          return (
            <div style={styles.imageDiv} key={img.url}>
              <ImageDeleteButton
                file={img.url}
                imageArray={imageArray}
                setAllData={setAllData}
              />
              <iframe
                key={img.url}
                src={img.url}
                title={img.url}
                width={maxW}
                height={maxH}
              ></iframe>
              <p style={styles.smallText}>{img.url}</p>
            </div>
          )
        } else {
          return displayImage(oops)
        }
      })}
    </div>
  ) : null
}

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
  },
  smallText: {
    fontFamily: 'open-sans',
    color: 'darkgray',
    fontSize: 10,
    hyphens: 'auto',
  },
  errorText: {
    fontFamily: 'open-sans',
    color: 'red',
    fontSize: 10,
  },
  imageDiv: {
    width: maxW,
    margin: 10,
  },
  center: {
    margin: 'auto',
  },
  warning: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'red',
    marginTop: 50,
  },
}

export default ImageDisplayer
