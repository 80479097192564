import useSWR from 'swr'
import { Link } from 'react-router-dom'
import { BsGear } from 'react-icons/bs'
import { FaInstagram } from "react-icons/fa6"
import { isMobile } from 'react-device-detect'
import { AiOutlineFacebook } from "react-icons/ai"
import { PageLoader } from './Loader'
import '../styles/Footer.css'
import { addApos } from '../utils/regexInput'
import HoursComp from './HoursComp'

const fetcher = (url) => fetch(url).then((res) => res.json())
const url = 'https://qualityearthwork.com/php/getAddress.php'
const hoursUrl = 'https://qualityearthwork.com/php/getHours.php'

const Footer = () => {
    const { data, error, isLoading } = useSWR(url, fetcher)
    const { data: hoursData, error: hoursError, isLoading: hoursIsLoading } = useSWR(hoursUrl, fetcher)

    const currentYear = new Date().getFullYear().toString()
    const startYear = `2019`
    const copyright =
        currentYear === startYear ? startYear : `${startYear}-${currentYear}`

    if (error || hoursError) return <h2>Error. Refresh the page.</h2>
    if (isLoading || hoursIsLoading) return <PageLoader />

    return (
        <footer>
            <div style={styles.addressHours}>
                <div style={styles.addressSocial}>

                    <p style={styles.company}>{addApos(data[0].companyName)}</p>
                    <p style={styles.location}>{addApos(data[0].location)}</p>
                    <a style={styles.phone} href={`tel:${data[0].phone}`}>{data[0].phone}</a>

                    <div style={styles.social}>
                        <a
                            href='https://www.instagram.com/quality_earthwork/?igsh=MXBneGlpbXN3MmRvMA%3D%3D'
                            alt='instagram'
                            title='Instagram'
                            rel="noreferrer"
                            target="_blank"
                        >
                            <FaInstagram size={30} style={styles.socialBtn} />
                        </a>

                        <a
                            href='https://www.facebook.com/QualityEarthwork'
                            alt='facebook'
                            title='Facebook'
                            rel="noreferrer"
                            target="_blank"
                        >
                            <AiOutlineFacebook size={32} style={styles.socialBtn} />
                        </a>
                    </div>
                </div>

                <HoursComp hoursData={hoursData} />
            </div>

            <div className="footer__content">
                <Link style={styles.gear} to="/admin" title="Admin">
                    <BsGear color="#6a6767" size="25px" className='footer_gear' />
                    <div style={{ width: isMobile ? 0 : 95 }}></div>
                </Link>

                <p style={styles.copyright} title="Go to akaDesignTech.com">
                    &copy; {copyright} Quality Earthwork & Hauling LLC /{' '}
                    <a
                        href="http://akaDesignTech.com"
                        rel="noreferrer"
                        target="_blank"
                        className='footer_link'
                    >
                        DesignTech
                    </a>
                </p>
            </div>
        </footer>
    )
}

const styles = {
    addressSocial: {
        flex: 2.5,
    },
    company: {
        textAlign: isMobile ? 'center' : 'left',
        fontSize: isMobile ? '1.6rem' : '1.7rem',
        fontWeight: 'bold',
        display: 'block',
    },
    location: {
        textAlign: isMobile ? 'center' : 'left',
        fontSize: isMobile ? '1.25rem' : '1.4rem',
        fontWeight: 'bold',
        display: 'block',
        marginTop: isMobile ? 10 : 7,

    },
    phone: {
        textAlign: isMobile ? 'center' : 'left',
        fontSize: isMobile ? '1.25rem' : '1.4rem',
        fontWeight: 'bold',
        display: 'block',
        marginTop: isMobile ? 10 : 7,
    },
    addressHours: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginTop: 30,
        marginBottom: 30,
    },
    social: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: isMobile ? 'center' : 'flex-start',
    },
    socialBtn: {
        marginTop: 15,
        marginRight: 20,
        marginBottom: isMobile ? 30 : -10,
    },
    gear: {
        flex: 1,
    },
    copyright: {
        flex: 3,
        textAlign: 'right'
    },
}

export default Footer
