import { TiDelete } from 'react-icons/ti'
import { handleImageDelete } from '../utils/handleImageDelete'

const ImageDeleteButton = (props) => {
  const { file, imageArray, setAllData } = props

  const handleDeleteFile = async () => {
    handleImageDelete(file)
    const newArray = imageArray.replace(file, '').replace(/,,/g, ',')
    setAllData(prev => ({
      ...prev,
      homeImages: newArray
    }))
  }

  return (
    <TiDelete
      color="#a80010"
      size={30}
      style={styles.deleteButton}
      onClick={handleDeleteFile}
    />
  )
}

const styles = {
  deleteButton: {
    transform: 'translateY(35px)',
    cursor: 'pointer',
  },
}

export default ImageDeleteButton
