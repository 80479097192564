import { useState } from 'react'
import { useNavigate } from "react-router-dom"
import LoginForm from './LoginForm'
import '../styles/Admin.css'
import EditHome from './EditHome'
import EditServices from './EditServices'
import EditAddress from './EditAddress'
import EditHours from './EditHours'
import EditTeam from './EditTeam'
import EditReviews from './EditReviews'

const secretPassword = process.env.REACT_APP_PASSWORD

const Admin = () => {
    let navigate = useNavigate()

    const [page, setPage] = useState('')
    const [loggedIn, setLoggedIn] = useState(sessionStorage.getItem('password') === secretPassword)
    const [editView, setEditView] = useState(undefined)

    const handleLogin = (password) => {
        sessionStorage.setItem('password', password)
        setLoggedIn(sessionStorage.getItem('password') === secretPassword)
    }

    const handleLogout = () => {
        sessionStorage.removeItem('password')
        setPage('')
        setLoggedIn(false)
        navigate('/')
    }

    const handleEditHome = () => {
        setPage('home')
        setEditView(<EditHome />)
    }

    const handleEditTeam = () => {
        setPage('team')
        setEditView(<EditTeam />)
    }

    const handleEditServices = () => {
        setPage('services')
        setEditView(<EditServices />)
    }

    const handleEditAddress = () => {
        setPage('address')
        setEditView(<EditAddress />)
    }

    const handleEditHours = () => {
        setPage('hours')
        setEditView(<EditHours />)
    }

    const handleEditReviews = () => {
        setPage('reviews')
        setEditView(<EditReviews />)
    }

    return (
        <div className='admin'>

            <h1>Admin.</h1>

            {loggedIn &&
                <div>
                    <button
                        className='logout'
                        onClick={handleLogout}
                    >Log out</button>

                    <div className="admin_buttons">

                        <button
                            onClick={handleEditHome}
                            disabled={page === 'home'}
                        >Edit Home</button>

                        <button
                            onClick={handleEditTeam}
                            disabled={page === 'team'}
                        >Edit Team</button>

                        <button
                            onClick={handleEditServices}
                            disabled={page === 'services'}
                        >Edit Services</button>

                        <button
                            onClick={handleEditAddress}
                            disabled={page === 'address'}
                        >Edit Address</button>

                        <button
                            onClick={handleEditHours}
                            disabled={page === 'hours'}
                        >Edit Hours</button>

                        <button
                            onClick={handleEditReviews}
                            disabled={page === 'reviews'}
                        >Edit Reviews</button>

                    </div>
                </div>
            }

            {editView}

            {loggedIn && !editView
                ? <h4 style={{ fontSize: 17, textAlign: 'center' }}>
                    Edit the home page, the team members, the services page, your address (company name, location, phone number), your hours of operation, and reviews.
                </h4>
                : !loggedIn &&
                <LoginForm
                    login={handleLogin}
                />
            }
        </div>
    )
}

export default Admin
