import { useState } from 'react'
import useSWR from 'swr'
import validator from 'validator'
import { PageLoader } from '../components/Loader'
import { SectionLoader } from '../components/Loader'
import MetaDecorator from '../components/MetaDecorator'
import '../styles/forms.css'
import { generateCustomerHTML, generateMyHTML } from '../utils/generateEmailHTML'

const fetcher = (url) => fetch(url).then((res) => res.json())
const url = 'https://qualityearthwork.com/php/getAddress.php'

const sendContactEmailsUrl = 'https://qualityearthwork.com/php/sendContactEmails.php'
// let count = 0

const Contact = () => {
    const [questionType, setQuestionType] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [time, setTime] = useState('')
    const [car, setCar] = useState('')
    const [message, setMessage] = useState('')
    const [error, setError] = useState('')
    const [submitted, setSubmitted] = useState(null)

    const { data, error: swrError, isLoading } = useSWR(url, fetcher)
    if (swrError) return <div><h1>Error.</h1><p>{swrError}</p></div>
    if (isLoading) return <PageLoader />

    const clearFields = () => {
        setName('')
        setEmail('')
        setPhone('')
        setTime('')
        setCar('')
        setMessage('')
        setError('')
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setSubmitted(false)

        if (validator.isEmail(email) && name !== '') {
            let info = {
                questionType,
                name: validator.escape(name),
                email,
                phone: validator.escape(phone),
                time,
                car: validator.escape(car),
                message: validator.escape(message),
            }

            const data = {
                customerHTML: generateCustomerHTML(info),
                myHTML: generateMyHTML(info),
                name: validator.escape(name),
                email,
            }

            fetch(sendContactEmailsUrl, {
                method: 'POST',
                body: JSON.stringify(data),
            })
                .then((res) => {
                    console.log('Response received: ', res.ok)
                    if (res.ok) {
                        setSubmitted(true)
                        clearFields()
                    }
                })
                .catch((e) => console.log('ERROR: ', e.message))
        } else {
            setError('You must provide a name and valid email address')
        }
    }

    const handleRadioButtons = (e) => {
        setQuestionType(e.target.value)
        setSubmitted(null)
        setError('')
    }

    // console.log(count++);

    return (
        <div>
            <MetaDecorator
                title="Quality Earthwork & Hauling LLC"
                description="Ask about our services or get a free estimate. "
                url="https://qualityearthwork.com/contact"
            />

            <h1>Contact.</h1>

            <h2>Call us.</h2>

            <p style={styles.phoneNumber}>
                <a href={`tel:${data[0].phone}`}>{data[0].phone}</a>
            </p>

            <h2>Send us an email.</h2>

            <div className="formContainer" style={styles.formContainer}>
                <form className="main">
                    <input
                        type="radio"
                        id="question"
                        name="questionType"
                        value="question"
                        onChange={handleRadioButtons}
                        checked={questionType === '' || questionType === 'question'}
                    />

                    <label htmlFor="question" style={styles.radio}>I have a question or comment</label>

                    <br />

                    <input
                        type="radio"
                        id="estimate"
                        name="questionType"
                        value="estimate"
                        style={{ marginTop: 15 }}
                        onChange={handleRadioButtons}
                    />

                    <label htmlFor="estimate" style={styles.radio}>I would like a free estimate</label>
                    <br />

                    <br />

                    <div className="inputGroup">
                        <label title="Required" htmlFor="name">Name *</label>
                        <input
                            type="text"
                            name="name"
                            value={name}
                            className="inputField"
                            required
                            onChange={(e) => {
                                setError('')
                                setSubmitted(null)
                                setName(e.target.value)
                            }}
                        />
                    </div>

                    <div className="inputGroup">
                        <label title="Required" htmlFor="email">Email *</label>
                        <input
                            type="email"
                            name="email"
                            value={email}
                            className="inputField"
                            required
                            onChange={(e) => {
                                setError('')
                                setSubmitted(null)
                                setEmail(e.target.value)
                            }}
                        />
                    </div>

                    <div className="inputGroup">
                        <label htmlFor="phone">Phone</label>
                        <input
                            type="tel"
                            name="phone"
                            value={phone}
                            className="inputField"
                            maxLength={20}
                            onChange={(e) => {
                                setError('')
                                setSubmitted(null)
                                setPhone(e.target.value)
                            }}
                        />
                    </div>

                    <div className="inputGroup">
                        <label htmlFor="time">
                            If you would like us to call you, what is the best time?
                        </label>

                        <select
                            name="time"
                            id="time"
                            value={time}
                            className="select"
                            onChange={(e) => {
                                setError('')
                                setSubmitted(null)
                                setTime(e.target.value)
                            }}
                        >
                            <option value="none">--</option>
                            <option value="morning (8a-12p)">Morning (8am - 12pm CST)</option>
                            <option value="afternoon (12p-5p)">
                                Afternoon (12pm - 5pm CST)
                            </option>
                            <option value="evening (5p-9p)">Evening (5pm - 9pm CST)</option>
                        </select>
                    </div>

                    <div className="inputGroup">
                        <label htmlFor="message">
                            Question, Comment, or Job Description
                        </label>
                        <textarea
                            maxLength="10000"
                            name="message"
                            value={message}
                            className="textarea"
                            onChange={(e) => {
                                setError('')
                                setSubmitted(null)
                                setMessage(e.target.value)
                            }}
                        />
                    </div>

                    <input
                        type="submit"
                        value="Submit"
                        className="submit"
                        onClick={(e) => {
                            handleSubmit(e)
                        }}
                    />

                    <p className="error">{error}</p>
                    <h3 className="success">
                        {submitted === true
                            ? <span>Success! Your message was sent!<br />Please check your spam/junk folders and add us to your Safe Senders list.</span>
                            : submitted === null
                                ? ''
                                : <SectionLoader />}
                    </h3>
                </form>
            </div>
        </div>
    )
}

const styles = {
    phoneNumber: {
        marginBottom: 30,
        fontSize: 30,
    },
    formContainer: {
        marginTop: 10,
    },
    radio: {
        fontSize: 16,
    }
}

export default Contact
