import { useState, useEffect } from 'react'
import useSWR from 'swr'
import { Link } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import banner from '../assets/banner.png'
import bannerInverted from '../assets/bannerInverted.png'
import Carousel from '../components/Carousel'
import { PageLoader } from '../components/Loader'
import MetaDecorator from '../components/MetaDecorator'
import '../styles/Home.css'
import { addApos } from '../utils/regexInput'
import ReviewsComp from '../components/ReviewsComp'

const fetcher = (url) => fetch(url).then((res) => res.json())
const homeUrl = 'https://qualityearthwork.com/php/getHome.php'

// let count = 0
const Home = (props) => {
    // console.log(count++)

    const [mode, setMode] = useState('')
    const [images, setImages] = useState([])

    useEffect(() => {
        if (
            window.matchMedia &&
            window.matchMedia('(prefers-color-scheme: dark)').matches
        ) {
            setMode('dark')
        } else {
            setMode('light')
        }

        window
            .matchMedia('(prefers-color-scheme: dark)')
            .addEventListener('change', (event) => {
                const newColorScheme = event.matches ? 'dark' : 'light'
                setMode(newColorScheme)
            })
    }, [])

    const source = mode === 'light' ? banner : bannerInverted

    const { data: homeData, error: homeError, isLoading: homeIsLoading } = useSWR(homeUrl, fetcher)

    const callToAction = homeData && addApos(homeData[0].callToAction)

    useEffect(() => {
        if (homeData && homeData[0] && homeData[0].homeImages) {
            let temp = homeData[0].homeImages.split(',')
            temp = temp.filter((i) => i.includes('http'))
            setImages(temp)
        }
    }, [homeData])

    if (homeError) return (
        <div>
            <h1>Error.</h1>
            <h3 style={{ marginLeft: 8 }}>Please refresh the page.</h3>
        </div>
    )
    if (homeIsLoading) return <PageLoader />

    return (
        <div className="home">
            <MetaDecorator />

            <img
                src={source}
                style={{
                    width: '100%',
                    maxHeight: 1080,
                    marginBottom: 20,
                }}
                alt="Quality Earthwork & Hauling"
            />
            <p style={styles.callToAction}
                dangerouslySetInnerHTML={{
                    __html: callToAction,
                }}
            ></p>

            <div style={styles.buttonContainer}>
                <a href='/about'
                    style={{
                        color: '#a80010',
                        fontWeight: 600,
                        display: 'inline',
                        textDecorationLine: 'underline',
                        fontSize: 20,
                        marginLeft: 5,
                    }}
                >
                    <button style={styles.button}>Our Company</button>
                </a>

                <Link
                    to="/services"
                    style={{
                        color: '#a80010',
                        fontWeight: 600,
                        display: 'inline',
                        textDecorationLine: 'underline',
                        fontSize: 20,
                        marginLeft: 5,

                    }}
                >
                    <button style={styles.button}>Our Services</button>
                </Link>

                <Link
                    to="/contact"
                    style={{
                        color: '#a80010',
                        fontWeight: 600,
                        display: 'inline',
                        textDecorationLine: 'underline',
                        fontSize: 20,
                        marginLeft: 5,
                        width: 170,
                    }}
                >
                    <button style={styles.button}>Contact us!</button>
                </Link>
            </div>

            <div style={{ height: 40 }}></div>
            <ReviewsComp />
            <Carousel images={images} />


        </div >
    )
}

const styles = {
    contact: {
        fontSize: 20,
    },
    callToAction: {
        fontSize: 18,
        fontStyle: 'italic',
        marginBottom: 30,
    },
    buttonContainer: {
        display: 'flex',
        flexWrap: isMobile && 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button: {
        width: isMobile ? 170 : '',
        fontSize: isMobile ? 17 : '',
        marginBottom: isMobile && 8,
    }
}

export default Home
