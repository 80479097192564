import { addApos } from '../utils/regexInput'
import avatar from '../assets/avatar.png'
import { isMobile } from 'react-device-detect'

const TeamComp = (props) => {
    const { teamData } = props

    const RenderItem = (itemData) => {
        const item = itemData.item

        return (
            <div id='team' style={styles.card}>
                <img
                    src={
                        item.image && item.image.includes('http')
                            ? item.image
                            : avatar
                    }
                    alt={item.name}
                    style={styles.imgStyle}
                />
                <h3 style={styles.nameStyle}>{item.name}</h3>
                <p style={styles.descStyle}
                    dangerouslySetInnerHTML={{
                        __html: addApos(item.description),
                    }}></p>

                {item.email && <p style={styles.emailStyle}>Email: <a href={`mailto:${item.email}`} alt={item.email}>{item.email}</a></p>}

            </div >
        )
    }

    return (
        <div id='team' style={styles.container}>
            <h1 style={styles.heading}>Our Team</h1>
            {teamData.map(i => <RenderItem item={i} key={i.id} />)}
        </div>
    )
}

const styles = {
    container: {
        width: '100%',
    },
    imgStyle: {
        borderRadius: '50%',
        width: 150,
        float: isMobile ? '' : 'right',
        marginLeft: isMobile ? 0 : 30,
        marginBottom: 15,
    },
    nameStyle: {
        fontSize: 30,
        textAlign: 'left'
    },
    descStyle: {
        marginBottom: 0,
    },
    emailStyle: {
        textAlign: 'left',
        margin: 0,
    }
    ,
    heading: {
        fontSize: 60,
        marginTop: 80,
    },
    card: {
        border: '1px solid',
        marginBottom: 20,
        padding: 30,
        borderRadius: 10,
        minHeight: 215,
        boxShadow: '1px 2px 5px rgba(0,0,0,0.5)',
        display: isMobile && 'flex',
        flexDirection: 'column'
    },
}

export default TeamComp
