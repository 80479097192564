import { Link, useLocation } from 'react-router-dom'
import '../styles/Header.css'
import menuItems from './menuItems'

const Header = () => {
    const location = useLocation()
    const pathname = location.pathname

    return (
        <header className="site__header">
            <div className="nav__area__inner">

                <Link to="/" className="nav__area__logo">
                    {/* <img
                            className={isMobile ? 'nav__logo_mobile' : 'nav__logo'}
                            src={source}
                            alt="I HATE CARS"
                        /> */}
                    <h3>Quality Earthwork & Hauling LLC</h3>
                </Link>


                <nav>
                    <ul className="menus">
                        {menuItems.map(({ url, label }, index) => (
                            <li
                                className={
                                    pathname === url ? 'menu__items_active' : 'menu__items'
                                }
                                key={index}
                            >
                                <Link to={url}>{label}</Link>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </header>
    )
}



export default Header
