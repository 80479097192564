import { removeApos } from '../regexInput'

const saveServices = async (rawData) => {
    const data = {
        id: rawData.id,
        service: removeApos(rawData.service),
        description: removeApos(rawData.description),
        place: rawData.place,
    }

    const url = 'https://qualityearthwork.com/php/postServices.php'

    await fetch(url, {
        method: 'POST',
        body: JSON.stringify(data),
    })
        .catch((e) => console.log('Error saving Service: ', e.message))

    return true
}

export default saveServices