import './App.css'
import { Routes, Route } from 'react-router-dom'
import Home from './pages/Home'
import Services from './pages/Services'
import About from './pages/About'
import Contact from './pages/Contact'
import Layout from './components/Layout'
import Admin from './admin/Admin'
import EditSingleService from './admin/EditSingleService'
import EditTeamMember from './admin/EditTeamMember'
import EditReview from './admin/EditReview'

// const store = createStore(rootReducer, compose(applyMiddleware(thunk)))
//https://redux.js.org/introduction/why-rtk-is-redux-today

function App() {
  return (
    <main>
      <Layout>
        <Routes>
          <Route path='/' element={<Home />} exact />
          <Route path='/admin' element={<Admin />} />
          <Route path='/services' element={<Services />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/about' element={<About />} />
          <Route path='/service/:id' element={<EditSingleService />} />
          <Route path='/member/:id' element={<EditTeamMember />} />
          <Route path='/review/:id' element={<EditReview />} />
        </Routes>
      </Layout>
    </main>
  )
}

export default App
