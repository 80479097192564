import { useState } from 'react'
import useSWR from 'swr'
import { PageLoader, SectionLoader } from '../components/Loader'
import ListOfServices from './ListOfServices'
import '../styles/forms.css'
import { addApos, removeApos } from '../utils/regexInput'
import saveServices from '../utils/saveToDb/saveServices'
import { isMobile } from 'react-device-detect'

const fetcher = (url) => fetch(url).then((res) => res.json())
const servicesUrl = 'https://qualityearthwork.com/php/getServices.php'

const EditServices = () => {

  const [message, setMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [service, setService] = useState('')
  const [description, setDescription] = useState('')
  const [place, setPlace] = useState('')

  const { data, error, isLoading: servicesIsLoading } = useSWR(servicesUrl, fetcher, { refreshInterval: 2000 })

  if (servicesIsLoading) return <PageLoader />
  if (error || !data) return (
    <div>
      <h1>Error.</h1>
      <h2>Server error. Refresh the page.</h2>
    </div>
  )

  const handleChangeService = (e) => {
    setMessage('')
    setService(e.target.value)
  }

  const handleChangeDescription = (e) => {
    setMessage('')
    setDescription(e.target.value)
  }

  const handleChangePlace = (e) => {
    setMessage('')
    setPlace(e.target.value)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    const res = await saveServices({
      service: removeApos(service),
      description: removeApos(description),
      place
    })
    setIsLoading(false)
    res && setMessage('Success!')
    setService('')
    setDescription('')
    setPlace('')
  }

  return (
    <div style={styles.main}>
      <h2 style={styles.heading}>Add a Service.</h2>

      <div style={styles.form}>
        <div style={styles.group}>
          <div style={styles.label}>Service</div>
          <input
            type="text"
            name="service"
            value={addApos(service)}
            className="inputField"
            onChange={handleChangeService}
            style={styles.input}
          />
        </div>

        <div style={styles.group}>
          <div style={styles.label}>Description</div>
          <textarea
            type="text"
            name="description"
            value={addApos(description)}
            className="inputField"
            onChange={handleChangeDescription}
            style={styles.textarea}
          />
        </div>

        <div style={styles.group}>
          <div style={styles.label}>Placement</div>
          <input
            type='text'
            name="place"
            value={addApos(place)}
            className="inputField"
            onChange={handleChangePlace}
            style={styles.input}
            placeholder='largest number will display first'
          />
        </div>

        <div style={styles.submit}>
          <input
            type="submit"
            value="SUBMIT"
            className="submit"
            onClick={handleSubmit}
          />
        </div>

        <p style={styles.success}>{message}</p>

        {isLoading && <SectionLoader />}
      </div>

      <ListOfServices data={data} />
    </div>
  )
}

const styles = {
  main: {
    margin: 'auto',
  },
  form: {
    padding: isMobile ? 20 : 30,
    border: '1px solid',
    margin: isMobile ? 5 : 30,
  },
  group: {
    display: 'flex',
    flexDirection: isMobile ? 'column' : 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  heading: {
    textAlign: isMobile ? 'center' : 'left',
    marginTop: isMobile ? 40 : 'auto',
    marginBottom: isMobile ? 20 : 'auto',
  },
  label: {
    fontSize: 20,
    flex: 2,
  },
  success: {
    color: '#04aa6d',
    fontSize: 20,
    marginLeft: 20,
    textAlign: 'right'
  },
  input: {
    flex: 4,
  },
  textarea: {
    flex: 4,
    height: 100,
  },
  submit: {
    width: '100%',
    margin: 'auto',
    textAlign: isMobile ? 'center' : 'right',
    marginTop: 20,
  }
}

export default EditServices
